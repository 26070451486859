
<main class="container">

	<h1>iCal to Roam Research</h1>

	<p class="lead">A small utility that let's you convert iCal files to a JSON file that can be used to import to Roam Research</p>

	<h2>Files</h2>


	<div class="row">
		<div class="col-md-6">
			<div class="form-group">
				<p>Start with selecting the <code>.ics</code> files you want to use:</p>
				<input class="form-control" type="file" bind:files multiple accept=".ics" on:change={(event) => onFilesSelected(event)} >
			</div>
		</div>
		<div class="col-md-6">
			{#if files}
				<p>Selected  <code>.ics</code> files:</p>
				<div class="vstack gap-3">
					{#each files as file}
						<div class="bg-light rounded-1 p-2">{file.name}</div>
					{/each}
				</div>
			{/if}
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			{#if loading}
				<div class="alert alert-info mt-2 mb-2" role="alert">
					<span class="text-center">Loading events...</span>
				</div>
			{:else if eventsOriginals.length > 0 && !loading }
				<h2>Configuration</h2>

				<p>Below you can edit the import node text, filter on specific start and end-dates or a use a text filter to only select specific events.</p>

				<form class="form-inline">
					<div class="">
						<div class="col-12">
							<label for="textFilter">Import Node text</label>
							<input type="text" class="form-control mb-2 mr-sm-2" bind:value={importTextNode} id="importTextNode">
						</div>
					</div>
					<div class="row">
						<div class="col-3">
							<label for="textFilter">Filter</label>
							<input type="text" class="form-control mb-2 mr-sm-2" bind:value={filterText} on:change={() => onFilterChanged()} id="textFilter" placeholder="filter">
						</div>
						<div class="col-3">
							<label for="dateStart">Date start</label>
							<input type="date" class="form-control mb-2 mr-sm-2" bind:value={filterDateStart} on:change={() => onFilterChanged()} id="dateStart">
						</div>
						<div class="col-3">
							<label for="dateEnd">Date end</label>
							<input type="date" class="form-control mb-2 mr-sm-2" bind:value={filterDateEnd} on:change={() => onFilterChanged()} id="dateEnd">
						</div>
						<div class="col-3">
							<label for="downloadJSON">Generate your JSON file</label>
							<button type="submit" class="btn btn-primary mb-2" id="downloadJSON" on:click|preventDefault={() => handleDownload()}>
								Download
							</button>
						</div>
					</div>
				</form>

				<h2>Preview 
					<span class="badge rounded-pill bg-primary">{events.length} events</span>
					<span class="badge rounded-pill bg-info">{days.length} days</span>
				</h2>

				<table class="table table-striped">
					<thead>
						<tr>
							<th>Summary</th>
							<th>Day</th>
						</tr>
					</thead>
					<tbody>
						{#each events as event}
							<tr>
								<td style="width: 50%">
									{event.summary}<br />
									<small class="text-muted">{event.description}</small>
								</td>
								<td>
									{event.dates.day}<br />
									<small class="text-muted">
									{moment(event.dates.start).toISOString()}
									-
									{moment(event.dates.end).toISOString()}
									</small>
								</td>
							</tr>
						{/each}
					</tbody>
				</table>
			{/if}
		</div>
	</div>
</main>

<script>
	import ICalParser from 'ical-js-parser';
	import _ from 'lodash';
	import moment from 'moment';

	let data = {};
	let days = [];
	let files = [];
	let events = [];
	let eventsOriginals = [];
	let loading = false;
	let filterDateStart;
	let filterDateEnd;
	let filterText;
	let importTextNode = "Calendar events on this day (#ical2roam #import)";

	// as per: https://stackoverflow.com/questions/8657958/how-to-parse-calendar-file-dates-with-javascript
	function parseIcalDate(icalStr)  {
		// icalStr = '20110914T184000Z'             
		var strYear = icalStr.substr(0,4);
		var strMonth = parseInt(icalStr.substr(4,2),10) - 1;
		var strDay = icalStr.substr(6,2);
		var strHour = icalStr.substr(9,2);
		var strMin = icalStr.substr(11,2);
		var strSec = icalStr.substr(13,2);

		return new Date(strYear,strMonth, strDay, strHour, strMin, strSec)
	}

	function parseiCalStartDate(parsedEvent){
		if (parsedEvent.dtstart.value)
			return parseIcalDate(parsedEvent.dtstart.value)
		else {
			return parseIcalDate(parsedEvent.dtstart)
		}
	}

	function parseiCalEndDate(parsedEvent) {
		if (parsedEvent.dtend.value)
			return parseIcalDate(parsedEvent.dtend.value)
		else {
			return parseIcalDate(parsedEvent.dtend)
		}
	}

	function parseiCalDay(parsedEvent) {
		if (parsedEvent.dtstart.value)
			return moment(parseIcalDate(parsedEvent.dtstart.value)).format("MMMM Do, YYYY"); // "February 14th, 2010"
		else {
			return moment(parseIcalDate(parsedEvent.dtstart)).format("MMMM Do, YYYY"); // "February 14th, 2010"
		}
	}

	function parseiCalUid(parsedEvent) {
		if (parsedEvent.dtstart.value)
			return moment(parseIcalDate(parsedEvent.dtstart.value)).format("MM-DD-YYYY")
		else {
			return moment(parseIcalDate(parsedEvent.dtstart)).format("MM-DD-YYYY")
		}
	}

	const onFilterChanged = (event) => {
		let filteredEvents = [];
		
		if (filterDateStart && filterDateEnd) {
			filteredEvents = eventsOriginals.filter(event => event.dates.start > new Date(filterDateStart) && event.dates.end < new Date(filterDateEnd))
		} else if (filterDateStart) {
			filteredEvents = eventsOriginals.filter(event => event.dates.start > new Date(filterDateStart))
		} else if (filterDateEnd) {
			filteredEvents = eventsOriginals.filter(event => event.dates.end < filterDateEnd)
		} else {
			filteredEvents = eventsOriginals
		}

		if (filterText) {
			filteredEvents = filteredEvents.filter(event => {
				return event.summary.toLowerCase().includes(filterText.toLowerCase()) || event.description.toLowerCase().includes(filterText.toLowerCase())
			})
		}

		events = filteredEvents
	}

	const onFilesSelected = (event) => {
		loading = true
		for (const index in Array.from(event.target.files)) {
			let file = event.target.files[index]
			console.log(`Parsing ${file.name}`)

			let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = event => {
					// Slice of the base64 encoded start...
					let iCalData = atob(event.target.result.slice(26))

					// Parse to JSON
					let parsedCalendar = ICalParser.toJSON(iCalData)

					parsedCalendar.events.forEach((event, index) => {
						parsedCalendar.events[index].dates = {
							start: parseiCalStartDate(parsedCalendar.events[index]),
							end: parseiCalEndDate(parsedCalendar.events[index]),
							day: parseiCalDay(parsedCalendar.events[index]),
							uid: parseiCalUid(parsedCalendar.events[index])
						}
					})
					events = _.sortBy(
						[...events, ...parsedCalendar.events],
						function(event) { return new Date(event.dates.start) }
					);
					eventsOriginals = events
					console.log(events[0])
				};
		}
	}

	function handleDownload(event) {
		let roamData = generateRoamJson(data)
		const blob = new Blob([JSON.stringify(roamData)], { type: 'application/json' });
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = 'import-to-roam.json';
		anchor.click();
		window.URL.revokeObjectURL(url);
	}

	function generateRoamJson(data) {
		let roamData = []
		Object.keys(data).forEach(key => {
			roamData.push({
				title: key,
				uid: data[key][0].dates.uid,
				children: [{
					string: importTextNode,
					children: generateRoamEvents(data[key])
				}]
			})
		})
		return roamData
	}

	function generateRoamEvents(events) {
		let roamEvents = []
		events.forEach(event => {
			let eventDescription = []

			// Description
			if (event.description) {
				eventDescription.push({
					string: event.description
				})
			}

			// Location
			if (event.location) {
				eventDescription.push({
					string: event.location
				})
			}
			
			// Start to end date
			eventDescription.push({
				string: moment(event.dates.start).toISOString() + ' - ' + moment(event.dates.end).toISOString()
			})

			roamEvents.push({
				string: event.summary,
				children: eventDescription
			})
		})
		return roamEvents
	}

	$: {
		loading = false;
		console.log(`Parsed ${events.length} events!`)

		// Group by date
		data = _.groupBy(events, e => e.dates.day);

		// Count unique days
		days = Object.keys(data);
	}
</script>
